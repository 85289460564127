import React from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import {
  getImage,
  StaticImage
} from "gatsby-plugin-image"
import { graphql } from "gatsby"
// modules
import Link from "../../modules/link"
// components
import Layout from "../../components/layout"
import TitleImage from "../../components/molecules/title_image"
import Paragraph from "../../components/atoms/paragraph"
import Information from "../../components/organisms/information"
import SEO from "../../components/seo"

const Wrapper = styled.div`
  align-items: ${props => props.alignItems};
  display: flex;
  flex-direction: ${props => props.flexDirection};
  grid-column-end: -2;
  grid-column-start: 2;
  justify-content: ${props => props.justifyContent};
  padding-bottom: 25px;
  padding-top: ${props => props.paddingTop};
`

const Recruit = ({
  location,
  data
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const paragraph = "只今株式会社マルトモでは新たに工事現場スタッフの募集を行っております。\n当社で様々な技術を学んでみませんか？\n貴方の応募をお待ちしております。"
  const table = [
    {
      header: "職種",
      data: "外装修繕工事全般"
    },
    {
      header: "仕事内容",
      data: "足場の組立て・解体作業\n壁塗装\nヒビ割れ・浮き等の補修\nシーリング（水漏れ防止の作業）\n屋上・ベランダの各種防水"
    },
    {
      header: "給与",
      data: "月給260,000円〜\n日給10,000円〜"
    },
    {
      header: "勤務時間",
      data: "7:30〜17:30"
    },
    {
      header: "勤務地",
      data: "都内各所現場"
    },
    {
      header: "休日・休暇",
      data: "日曜日\n夏季休暇、慶弔休暇、年末年始"
    },
    {
      header: "待遇・福利厚生",
      data: "社会保険完備\n家賃補助あり\n交通費全額支給\n自転車通勤OK\n残業ほぼ無し"
    }
  ]
  return (
    <Layout location={location}>
      <TitleImage
        image={getImage(data.file)}
        alt="" //
        heading="採用情報"
      />
        <Wrapper
          alignItems="center"
          flexDirection={isMobile && "column"}
          justifyContent={!isMobile && "center"}
          paddingTop="25px"
        >
          <Paragraph
            fontSize="15px"
            marginBottom={isMobile && "10px"}
            marginRight={!isMobile && "10px"}
            whiteSpace="pre-wrap"
            paragraph={paragraph}
          />
          <StaticImage
            src="../../images/IMG_6141.JPG"
            alt="" //
            layout="fixed"
            width={250}
          />
        </Wrapper>
      <Information
        paddingBottom="10px"
        heading="募集要項"
        table={table}
      />
      <Wrapper justifyContent="center">
        <Link
          to="/recruit/application"
          background="#67a70c"
          color="#ffffff"
          display="block"
          fontSize="15px"
          padding="10px 50px"
          $background="#26610f"
        >
          応募フォームはこちら
        </Link>
      </Wrapper>
    </Layout>
  )
}

export default Recruit

export const query = graphql`
  query {
    file(relativePath: { eq: "recruit.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          height: 250,
          layout: FIXED
        )
      }
    }
  }
`

export const Head = ({ location }) => (
  <SEO
    title="採用情報"
    description="株式会社マルトモの採用情報ページです。"
    pathname={location.pathname}
  />
)
